// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
(function($) {
    'use strict';

    if (!Foundation.stylesheet) {
        Foundation._style_element = $('<style></style>').appendTo('head')[0];
        Foundation.stylesheet = Foundation._style_element.styleSheet;

        if (Foundation.stylesheet) {
            Foundation.stylesheet.cssRules = {
                length: 0
            };

            Foundation.stylesheet.insertRule = function(rule, index) {
                var media;
                var mediaMatch;
                var mediaRegex;
                var namespace;
                var ruleMatch;
                var ruleRegex;
                mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                mediaMatch = mediaRegex.exec(rule);
                media = '';

                if (mediaMatch) {
                    media = '@media ' + mediaMatch[1] + ' ';
                    rule = mediaMatch[2];
                }

                ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                ruleMatch = ruleRegex.exec(rule);
                namespace = '' + media + ruleMatch[1];
                rule = ruleMatch[2];

                return this.addRule(namespace, rule);
            };
        } else if (window.console) {
            console.log('Could not fix Foundation CSS rules...');
        }
    }
})(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

//scroll
$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top-50
        }, 1000);
        return false;
      }
    }
  });
});
$(window).scroll(function(){
    if ($(this).scrollTop() > 300) {
      $('.top').fadeIn();
    } else {
      $('.top').fadeOut();
    }
  });
// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

$("nav:first").accessibleMegaMenu({
    /* prefix for generated unique id attributes, which are required
       to indicate aria-owns, aria-controls and aria-labelledby */
    uuidPrefix: "accessible-megamenu",

    /* css class used to define the megamenu styling */
    menuClass: "nav-menu",

    /* css class for a top-level navigation item in the megamenu */
    topNavItemClass: "nav-item",

    /* css class for a megamenu panel */
    panelClass: "sub-nav",

    /* css class for a group of items within a megamenu panel */
    panelGroupClass: "sub-nav-group",

    /* css class for the hover state */
    hoverClass: "hover",

    /* css class for the focus state */
    focusClass: "focus",

    /* css class for the open state */
    openClass: "open"
});






$( ".nav-menu a" ).on( "click", function() {
    $('.desplegable button').removeClass('open');
    $('.desplegable ul').attr('aria-hidden', 'true');

});
//si es touch prevenim click als dropdowns
if (feature.touch) {
    $( ".has-sub-nav > a" ).on( "click", function(event) {
        event.preventDefault();
        event.stopPropagation();
        $('ul.sub-level-group.level-3').hide();
        return false;
    });
}




$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // mobile-nav
    /*$('.menu-trigger').click(
        function() {
            if (!$('.main-nav').hasClass('open')) {
                $('.main-nav-container')
                    .addClass('is-visible')
                    .removeClass('is-hidden');
                $('.main-nav').addClass('open');
            } else {
                $('.main-nav-container')
                    .addClass('is-hidden')
                    .removeClass('is-visible');
                $('.main-nav').removeClass('open');
            }
            return false;
        }
    );*/

    // slider
      $('.slider').slick({
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
        cssEase: 'linear',
        nextArrow: "<img class='next slick-next' src='/media/img/slide-centre-endavant.png'>",
        prevArrow: "<img class='prev slick-prev' src='/media/img/slide-centre-enrera.png'>"
      });

       $('.slider-portada').slick({
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
        cssEase: 'linear',
        nextArrow: "<img class='next slick-next' src='/media/img/slide-centre-endavant.png'>",
        prevArrow: "<img class='prev slick-prev' src='/media/img/slide-centre-enrera.png'>"
      });

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }
   if($( window ).width() < 1000){
        $(".main-nav").attr("aria-hidden","true");
    }
    /*$( ".main-nav__button" ).on( "click", function() {
      var atribut = $('.menu-mobil').attr('aria-hidden');

      if(atribut == "true"){
        $(".menu-mobil").attr("aria-hidden","false").addClass("open");
        $(this).attr("aria-expanded","true").addClass("open").addClass("close");
      }else{
        $(".menu-mobil").attr("aria-hidden","true").removeClass("open");
        $(this).attr("aria-expanded","false").removeClass("open").removeClass("close");
      }
    });*/
    $( window ).resize(function() {
        if($( window ).width() > 1000){
            $(".menu-mobil").attr("aria-hidden","false").removeClass("open");
            $(".main-nav__button").attr("aria-expanded","false").removeClass("close").removeClass("open");
            $(".search-mobile").removeClass("open");
        }
    });

    $('.main-header .menu').addClass("load");

// ampliar imatge
    $('.popup-img').magnificPopup({
        tClose: 'Tancar (Esc)',
        tLoading: 'Carregant…',
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"><img src="/media/img/boto-tancar.png" alt="Tancar"/></button>',
        type: 'image',
        gallery: {
            enabled: true,
            tPrev: 'Anterior (Tecla de fletxa esquerra)',
            tNext: 'Següent (Tecla de fletxa dreta)',
            tCounter: '<span class="mfp-counter">%curr% de %total%</span>'
        },
        image: {
            cursor: null,
            markup: '<div class="mfp-figure">'+
                        '<div class="mfp-figure-container">'+
                            '<div class="mfp-close"></div>'+
                            '<div class="mfp-img"></div>'+
                            '<div class="mfp-bottom-bar">'+
                                '<div class="mfp-counter"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'
        }

    });
    // tancar botó magnific popup
    $(document).on('click', '.mfp-close', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
    });
    //matchHeight
    $(function() {
        $('.caixa-img').matchHeight();
        $('.caixetes li').matchHeight();
        $('.caixetes-2 li').matchHeight();
        $('.ics').matchHeight();

    });

    //Cercador
    /*$("#traductor, .tancar").click(function() {
        $("#cercador-box").hide();
        $("#idiomes").toggle('fast');
        return false;
    });*/
    $(".cercar").click(function() {
         $('#cercador-box').fadeIn();
         $( "input.cercar" ).focus();
        return false;
    });
    $('#s-1').click(function(e){
        e.stopPropagation();
    });
    $("#tancar-cerca, #cercador-box").click(function() {
        $("#cercador-box").hide();
        return false;
    });
    $('.caixetes li, .caixetes-2 li').wrapInner('<div class="wrap"></div>');
    $(".caixetes li, .caixetes-2 li").click(function(){
          var url = $(this).find("a").attr("href");
          if(url != undefined){
              window.location= url;
          }
          return false;
        });
    $(".caixetes li, .caixetes-2 li").css({"cursor": "pointer"});

    //Nou menú mobile*/
    $(".menu-btn").on("click", function() {
      // afegir classe al botó
      $(this).toggleClass("open");
      $(this).toggleClass("close");
      // afegir classe a l'overlay
      $(".overlay").toggleClass("is-active");
      // afegir classe al body
      $("body").toggleClass("js-menu-open");
      // afegir classe al menú
      $(".menu-mobile-lateral").toggleClass("is-active");
      // canviar atributs del botó
      if ($(this).attr("aria-expanded") == "true") {
        $(this).attr("aria-expanded", "false");
      } else {
        $(this).attr("aria-expanded", "true");
      }
    });
    $("body").on("click", ".overlay.is-active", function() {
        // afegir classe al botó
      $(".menu-btn").toggleClass("open");
      $(".menu-btn").toggleClass("close");

      // afegir classe a l'overlay
      $(".overlay").toggleClass("is-active");
      // afegir classe al body
      $("body").toggleClass("js-menu-open");
      // afegir classe al menú
      $(".menu-mobile-lateral").toggleClass("is-active");
      // canviar atributs del botó
      if ($(".menu-btn").attr("aria-expanded") == "true") {
        $(".menu-btn").attr("aria-expanded", "false");
      } else {
        $(".menu-btn").attr("aria-expanded", "true");
      }
    });


    $(".menu-mobile-lateral .has-sub-nav-mobile > a").append('<span class="arrow"></span>');
    $(".menu-mobile-lateral").on("click", ".has-sub-nav-mobile a span", function(e) {
      if ($(window).width() < 700 || $("html").hasClass("touch")) {
        e.preventDefault();
        if (
          $(this).parent()
            .next("ul")
            .is(":visible")
        ) {
          $(this).parent()
            .next("ul")
            .slideUp("fast");
          $(this).parent().removeClass("active");
        } else {
          $(this).parent()
            .closest("ul")
            .find(".active")
            .next("ul")
            .slideUp("fast");
          $(this).parent()
            .closest("ul")
            .find(".active")
            .removeClass("active");
          $(this).parent()
            .next()
            .slideToggle("fast");
          $(this).parent().addClass("active");
        }
      }
    });
});
